import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faMobile, faPalette, faCloud } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
    return (
        <div id="services">
            <h3>Servicios que prestamos</h3>
            <ul>
                <li>
                    <FontAwesomeIcon icon={faCoffee} />
                    <h3>Sitios Web</h3>
                    <span>El sitio web que tengas en mente, lo hacemos.</span>
                </li>
                <li>
                    <FontAwesomeIcon icon={faMobile} />
                    <h3>Aplicaciones</h3>
                    <span>Desarrollo de aplicaciones hechas a tu medida</span>
                </li>
                <li>
                    <FontAwesomeIcon icon={faPalette} />
                    <h3>Diseño Gráfico</h3>
                    <span>Imagen Corporativa, Diseño Editorial y publicitario</span>
                </li>
                <li>
                    <FontAwesomeIcon icon={faCloud} />
                    <h3>Servicio en la nube</h3>
                    <span>Migración de servicios a la Nube (AWS)</span>
                </li>
            </ul>
        </div>
    )
}

export default Services;