import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { MobileView } from 'react-device-detect';
import SVGComponent from './Logo.js';

const Header = () => {

    const [menu, setMenu] = useState(false);

    useEffect(() => {

        return () => {

        }

    }, [menu]);

    return (
        <header>
            <div className="inner">
                <MobileView>
                    <div className="bars">
                        <FontAwesomeIcon icon={faBars} onClick={(e) => setMenu(true)}></FontAwesomeIcon>                    
                    </div>
                </MobileView>
                {/* <BrowserView> */}
                    <div className="logo">
                        <a href="#home">
                            <SVGComponent></SVGComponent>
                        </a>
                    </div>
                {/* </BrowserView> */}
            </div>
            <nav onClick={(e) => setMenu(false)} className={menu ? "active" : "no-active"}>
                <MobileView>
                    <div className="fatimecircle">
                        <FontAwesomeIcon icon={faTimesCircle} onClick={(e) => setMenu(false)}></FontAwesomeIcon>
                    </div>
                </MobileView>
                <a href="#home">Home</a>
                <a href="#services">Servicios</a>
                <a href="#about">Acerca de nosotros</a>
                <a href="#tech">Tecnologías</a>
                <a href="#contact">Contacto</a>
            </nav>  
        </header>
    )
}

export default Header;
