import React from 'react';

const Highlight = () => {
    return (
        <div className="highlight" id="home">
            <div className="welcome">
                <h1>{process.env.REACT_APP_COMPANY}</h1>
                <p>{process.env.REACT_APP_SLOGAN}</p>
                <a className='btn btn-success' href="https://wa.me/50230998948" target="_blank" rel="noopener noreferrer" >Háblanos</a>
            </div>
            <div className="bg"></div>
        </div>
    )
}

export default Highlight;