import React, { useEffect, useState } from 'react';



const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('website');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [human, setHuman] = useState(false);

    useEffect(()=>{
        return()=>{
        };
    }, [name, email, phone, subject, message, success]);

    const submitForm = (e) => {
        
        e.preventDefault();

        const payload = {
            url: process.env.REACT_APP_LAMBDA_URL,
            from: process.env.REACT_APP_LAMBDA_URL,
            name: name,
            email: email,
            subject: subject,
            phone: phone,
            message: message,
            website: 'cloudstudio'
        }
        post(payload)
        .then((data) => {
            console.log("data", data);
            // this.setState({error: null, submitted: true});        
        })
        .catch(error => {
            console.log("err ", error);
            // this.setState({ error: error.message, submitted: false});
        })
    }
    

    const post = async (data) => {

        const { url } = data;
        
        delete data.url;
    
        const params = {
            method: 'POST',
            body: JSON.stringify(data)
        }
        const response = await fetch(url, params);        
        console.log(response, response.status);
        if(response.status === 200){
            console.log("success")
            document.getElementById("contact-form").reset();
            setSuccess(true);
            
        } else {
            const res = await response.json();        
            throw new Error(res);
        }
    }

    return (
        <div id="contact">
            <div className="bg-trans">
                <div className="signup container col-md-4">
                    <h2>Ponte en contacto</h2>
                    <div>
                        <form  onSubmit={submitForm} id="contact-form">
                        <div className="form-group item">
                            <label htmlFor="name">Nombre:</label>
                            <input type="text" id="name" name="name" message="Coloca tu nombre" className="form-control form-control-lg" required onChange={(e)=> setName(e.target.value)}></input>
                        </div>
                        <div className="form-group item">
                            <label htmlFor="phone">Teléfono:</label>
                            <input type="tel" id="phone" name="phone" className="form-control form-control-lg" required onChange={(e)=> setPhone(e.target.value)}></input>
                        </div>
                        <div className="form-group item">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" className="form-control form-control-lg" required onChange={(e) => setEmail(e.target.value)}></input>
                        </div>
                        <div className="form-group item">
                            <label htmlFor="subject">Asunto:</label>
                            <select className="form-control form-control-lg" onChange={(e) => setSubject(e.target.value)}>
                                <option value="website">Website</option>
                                <option value="apps">Aplicaciones</option>
                                <option value="graphicDesign">Diseño Gráfico</option>
                                <option value="cloud">Servicios en la Nube</option>
                                <option value="services">Otro</option>
                            </select>
                        </div>
                        <div className="form-group item">
                            <label htmlFor="mensaje">Tu mensaje:</label>
                            <textarea id="mensaje" name="mensaje" className="form-control form-control-lg" onChange={(e) => setMessage(e.target.value)}></textarea>
                        </div>
                        <div className="form-group item">
                            <span>Eres humano?</span> <div className="btn btn-secondary btn-lg" onClick={(e) => setHuman(true)}>Si!</div>
                        </div>
                        <div className="form-group item">
                            {human && <button className="btn btn-primary btn-lg">Enviar</button>}
                        </div>
                        {success && <div className="alert alert-success">Hola, {name}, tu mensaje ha sido enviado, pronto estaremos en contacto.</div>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;